/** 
 * import : 
 * 
 * @version 0.1 (2021.04.23 : usayama)
 * @version 0.2 (2021.05.13 : usayama)) // gatsby 仕様に変更
*/
import React from 'react'
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link } from "gatsby"
// conf, action
import { APP_ROUTE } from '@config/routers'
// components
// helper
// style, img
// import logoPath from "../../assets/imgs/logo.png"
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.04.23 : usayama)
*/
const Logo = props => {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.04.23 : usayama)
   * @version 0.2 (2021.05.13 : usayama)) // gatsby 仕様に変更
   */
  const { className, type } = props
  const classes = classNames( "logo", className, )
  const logoPath = require("@assets/images/logo" + ((type) ? "-" + type : "") + ".png")

  /** 
   * return : 
   * 
     * @version 0.1 (2021.04.23 : usayama)
   */
  return (
    <Link to={APP_ROUTE.root}><img src={logoPath.default} className={classes} alt="" /></Link>
  )
}

/** 
 * propTypes
 * 
 * @version 0.1 (2021.04.23 : usayama)
*/
Logo.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
}

/** 
 * defaultProps
 * 
 * @version 0.1 (2021.04.23 : usayama)
*/
Logo.defaultProps = {
  className: "",
  type: "",
}

/** 
 * export
 * 
 * @version 0.1 (2021.04.23 : usayama)
*/
export default Logo
