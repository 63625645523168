/** 
 * import : 
 * 
 * @version 0.1 (2021.05.18 : usayama_dx)
 * @version 0.2 (2021.05.20 : usayama) // getCsrfToken を store へ移動, ReduxModal 削除
 * @version 0.3 (2021.06.21 : usayama) // authActions 追加
*/
import React from 'react'
import { useDispatch } from 'react-redux'
// conf, action
import authActions from '@redux/auth/actions'
// components
// containers
// helper
// style, img
// consts
const { getMe } = authActions

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.18 : usayama_dx)
*/
export default function CommonContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.05.18 : usayama_dx)
   */
  // const { ...attrs } = props
  const dispatch = useDispatch()

  /** 
   * state : 
   * 
   * @version 0.1 (2021.05.18 : usayama)
  */
  const [initPage, setInitPage] = React.useState(false)

  /** 
   * state : 
   * 
   * @version 0.1 (2021.05.18 : usayama)
   * @version 0.2 (2021.05.20 : usayama) // getCsrfToken を store へ移動
   * @version 0.3 (2021.06.21 : usayama) // getMe 追加
   */
  React.useEffect(() => {
    if(!initPage) {
      dispatch(getMe())
      setInitPage(true)
    }
  }, [initPage, dispatch])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.18 : usayama_dx)
   * @version 0.2 (2021.05.20 : usayama_dx) // ReduxModal 削除
   */
  return (
      <>
      </>
  )
}
