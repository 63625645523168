/** 
 * import : 
 * 
 * @version 0.1 (2021.05.13, 05.14 : usayama)
*/
import React from 'react'
import { Link } from "gatsby"
// import { HiHome } from "react-icons/hi"
// conf, action
import { APP_ROUTE } from '@config/routers'
// components
import Logo from '@components/Typo/Logo' 
import Copyright from '@components/Typo/Copyright' 
import Label from '@components/Typo/Label'
// containers
// helper
// style, img, elm
// const

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.13, 05.14 : usayama)
*/
export default function Footer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.05.13, 05.14 : usayama)
   */
  // const location = useLocation()

  /** 
   * state : 
   * 
   * @version 0.1 (2021.05.13, 05.14 : usayama)
  */
  const [initPage, setInitPage] = React.useState(false)

  /** 
   * event : 
   * 
   * @version 0.1 (2021.04.28 : usayama)
   */
  /*
  const handleOnToggle = (e) => {
    var element = document.getElementById("app")
    element.classList.toggle("off-side-menu")
  }
  */

  /** 
   * componentDidMount
   * https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.05.13, 05.14 : usayama)
   */
  React.useEffect(() => {
    if(!initPage) {
      setInitPage(true)
    }
  }, [initPage])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.13, 05.14 : usayama)
   * @version 0.2 (2021.06.22 : usayama) // faq, about コメントアウト
   */
  return (
    <footer className="footer">
      <div className="">
        <ul>
          {/*<li><Link to={APP_ROUTE.about}><Label label="menu.about" /></Link></li>*/}
          <li><Link to={APP_ROUTE.terms}><Label label="menu.terms" /></Link></li>
          <li><Link to={APP_ROUTE.privacy}><Label label="menu.privacy" /></Link></li>
          <li><Link to={APP_ROUTE.legal}><Label label="menu.legal" /></Link></li>
          {/*<li><Link to={APP_ROUTE.faq}><Label label="menu.faq" /></Link></li>*/}
          <li><Link to={APP_ROUTE.contact}><Label label="menu.contact" /></Link></li>
        </ul>
        <h6><Logo /></h6>
        <Copyright />
      </div>
    </footer>
  )
}
