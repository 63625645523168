/** 
 * import : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
 * @version 0.2 (2021.06.15 : usayama) // cartActions, useDispatch 追加
 * @version 0.3 (2021.06.22 : usayama) // デザイン調整
*/
import React from 'react' 
import { useSelector, useDispatch } from 'react-redux'
// import { BsHeart } from "react-icons/bs" 
import { IoPersonCircleOutline, IoCartOutline } from "react-icons/io5" 
// conf, action
import { APP_ROUTE } from '@config/routers' 
import cartActions from '@redux/cart/actions'
// components
import Icon from '@components/UIKit/Icon' 
import Logo from '@components/Typo/Logo' 
import GreenLicenseLogo from '@components/Typo/GreenLicenseLogo' 
import Label from '@components/Typo/Label' 
// import Icon from '../components/UIKit/Icon' 
// containers
// helper
// style, img, elm
// const
const { getCartItems } = cartActions

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
*/
// export const useSelector: Header<RootState> = selector => {
export default function Header(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.15 : usayama)
   */
  const dispatch = useDispatch()

  /** 
   * state : 
   * 
   * @version 0.1 (2021.05.13 : usayama)
  */
  const [initPage, setInitPage] = React.useState(false) 
  const { cartItems } = useSelector(state => state.Cart)

  /** 
   * event : 
   * 
   * @version 0.1 (2021.04.28 : usayama)
   */
  /*
  const handleOnToggle = (e) => {
    var element = document.getElementById("app") 
    element.classList.toggle("off-side-menu") 
  } 
  */

  /** 
   * componentDidMount
   * https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.05.13 : usayama)
   * @version 0.2 (2021.06.15 : usayama) // getCartItems 追加
   */
  React.useEffect(() => {
    if(!initPage) {
      dispatch(getCartItems())
      setInitPage(true) 
    }
  }, [initPage, dispatch])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.13 : usayama)
   * @version 0.2 (2021.06.14 : usayama) //配列から object に変更
   * @version 0.3 (2021.06.22 : usayama) // デザイン調整, お気に入りコメントアウト
   * @version 0.4 (2021.06.24 : usayama) // グリーンライセンスロゴ追加
   * @version 0.5 (2021.06.30 : usayama) // className= に修正
   * @version 0.6 (2023.07.26 : usayama) // cart ボタンをコメントアウト
   * @version 0.7 (2023.08.01 : usayama) // mypage をコメントアウト
   */
  return (
    <header className="navbar header">
      <div className="container">
        <div className="col-auto logo-wrapper">
          <Logo />
          <GreenLicenseLogo />
        </div>
        <ul className="col-auto">
          {/*
          <li>
            <span>
              <Icon to={APP_ROUTE.like} icon={<BsHeart size={20} />} /><br />
              <span className="label"><Label label="menu.bookmark" /></span>
            </span>
          </li>
          */}
          {/*
          <li>
            <span>
              <Icon to={APP_ROUTE.mypage} icon={<IoPersonCircleOutline size={28} />} />
              <span className="label"><Label label="menu.mypage" /></span>
            </span>
          </li>
          */}
          {/*
          <li>
            <span>
              <Icon 
                to={APP_ROUTE.cart} 
                icon={<IoCartOutline size={28} />} 
                badge={cartItems && (Object.keys(cartItems).length > 0) ? Object.keys(cartItems).length : ""} 
                badgePosition={{ 'top': '5px', 'left': '40px'}}
              />
              <span className="label"><Label label="menu.cart" /></span>
            </span>
          </li>
          */}
        </ul>
      </div>
    </header>
  ) 
} 
