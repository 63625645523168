/** 
 * import : 
 * 
 * @version 0.1 (2021.01.20 : usayama_dx)
 * @version 0.2 (2021.05.14 : usayama_dx) // react-i18next から gatsby-plugin-react-i18next に変更
*/
import React from 'react'
import PropTypes from "prop-types"
import { useTranslation } from 'react-i18next'
// conf, action
// components
// helper
// style, img
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.01.20 : usayama_dx)
*/
const Label = props => {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.01.20 : usayama_dx)
   */
  const { t } = useTranslation()
  const { label, replaces } = props

  /** 
   * return : 
   * 
     * @version 0.1 (2021.01.20 : usayama_dx)
   */
  return (
    <>
      { replaces ? t(label, replaces) : t(label) }
    </>
  )

}

/** 
 * propTypes
 * 
 * @version 0.1 (2021.01.20 : usayama_dx)
*/
Label.propTypes = {
  label: PropTypes.string,
  replaces: PropTypes.object,
}

/** 
 * defaultProps
 * 
 * @version 0.1 (2021.01.20 : usayama_dx)
*/
Label.defaultProps = {
  label: "",
  replaces: null,
}

/** 
 * export
 * 
 * @version 0.1 (2021.01.20 : usayama_dx)
*/
export default Label
