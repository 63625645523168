/** 
 * import : 
 * 
 * @version 0.1 (2021.04.23 : usayama_dx)
 * @version 0.2 (2021.05.14 : usayama_dx) // i18n 変更
*/
import React from 'react'
import PropTypes from "prop-types"
import classNames from "classnames"
// conf, action
// components
import Label from '@components/Typo/Label'
// helper
// style, img
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.04.23 : usayama_dx)
*/
const Copyright = props => {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.04.23 : usayama_dx)
   * @version 0.2 (2021.05.14 : usayama_dx) // "copyright" 追加, i18n 変更, label 削除
   */
  const { className, ...attrs } = props
  const classes = classNames( 
    "copyright",
    className,
  )

  /** 
   * return : 
   * 
   * @version 0.1 (2021.04.23 : usayama_dx)
   * @version 0.2 (2021.04.26 : usayama_dx) // h6 に変更
   * @version 0.3 (2021.05.14 : usayama_dx) // div に変更
   */
  return (
    <div className={classes} {...attrs}><Label label="app.copyright" /></div>
  )
}

/** 
 * propTypes
 * 
 * @version 0.1 (2021.04.23 : usayama_dx)
 * @version 0.2 (2021.05.14 : usayama_dx) // "copyright" 追加, i18n 変更
*/
Copyright.propTypes = {
  className: PropTypes.string,
}

/** 
 * defaultProps
 * 
 * @version 0.1 (2021.04.23 : usayama_dx)
 * @version 0.2 (2021.05.14 : usayama_dx) // "copyright" 追加, i18n 変更
*/
Copyright.defaultProps = {
  className: "",
}

/** 
 * export
 * 
 * @version 0.1 (2021.04.23 : usayama_dx)
*/
export default Copyright

