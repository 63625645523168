/** 
 * import : 
 * 
 * @version 0.1 (2021.06.24 : usayama)
*/
import React from 'react'
import PropTypes from "prop-types"
import classNames from "classnames"
// conf, action
// components
import LabelHtml from '@components/typo/LabelHtml'
// helper
// style, img
// import logoPath from "../../assets/imgs/logo.png"
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.06.24 : usayama)
*/
const GreenLicenseLogo = props => {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.24 : usayama)
   */
  const { className, type } = props
  const classes = classNames( "d-inline-flex align-items-center license-logo", className, )
  const logoPath = require("@assets/images/green-license-logo" + ((type) ? "-" + type : "") + ".png")

  /** 
   * return : 
   * 
     * @version 0.1 (2021.06.24 : usayama)
     * @version 0.2 (2021.06.30 : usayama) // className= に修正
   */
  return <div className={classes}>
          <img src={logoPath.default} alt="" />
          <LabelHtml label="text.green_license" />
        </div>
}

/** 
 * propTypes
 * 
 * @version 0.1 (2021.06.24 : usayama)
*/
GreenLicenseLogo.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
}

/** 
 * defaultProps
 * 
 * @version 0.1 (2021.06.24 : usayama)
*/
GreenLicenseLogo.defaultProps = {
  className: "",
  type: "",
}

/** 
 * export
 * 
 * @version 0.1 (2021.06.24 : usayama)
*/
export default GreenLicenseLogo
