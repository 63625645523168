/** 
 * import : 
 * 
 * @version 0.1 (2021.05.13 : usayama_dx)
 * @version 0.2 (2021.05.16 : usayama_dx) // Tools/Seo 追加
 * @version 0.3 (2021.05.18 : usayama_dx) // CommonContainer 追加
*/
import React from 'react'
// conf, action
// components
// import RootProvider from '@src/provider'
// containers
import CommonContainer from "@containers/CommonContainer"
import HeaderContainer from "@containers/HeaderContainer"
import FooterContainer from "@containers/FooterContainer"
// helper
// style, img
import "@assets/css/style.css"
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.13 : usayama_dx)
*/
export default function LayoutContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.05.13 : usayama_dx)
   * @version 0.2 (2021.06.30 : usayama_dx) // classes 削除
   */
  const { children, ...attrs } = props

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.13 : usayama_dx)
   * @version 0.2 (2021.05.18 : usayama_dx) // CommonContainer 追加
   * @version 0.3 (2021.06.30 : usayama) // className= に修正
   */
  return (
      <div {...attrs}>
        <CommonContainer />
        <HeaderContainer />
        <main className="main">
          {children}
        </main>
        <FooterContainer />
      </div>
  )
}
